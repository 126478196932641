// @flow
import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects'
import { camelizeKeys, decamelizeKeys } from 'humps'
import { stringify } from 'utilities/apiQueryString'

import * as actions from './actions'
import { expireSession } from '../auth/actions'
import { success, failure } from 'utilities/actions'
import { getEndpointByDocType } from 'utilities/state'
import { API_URL, REPORTS_ENDPOINT, DOC_TYPES as ENDPOINT_TYPES, DOC_TYPES } from 'qap/constants'
import { requestActions } from 'state/request'
import { uploadFile } from 'state/uploads/sagas'
import { fetch } from 'api'
import { fetchMemberships } from 'state/memberships/actions'
import { fetchNextAndPreviousReportIds } from 'state/nextAndPreviousReportIds/actions'
import {
  fetchReports,
  fetchReportFacets,
  updateReportUserStatus,
  setSingleReportReviewStatus,
  setReportInProgress,
} from 'state/reports/actions'

function * fetchReport (action) : Generator<any, any, any> {
  const { reportId, options: { docType: endpointType } = {} } = action
  const url = `${API_URL}/${getEndpointByDocType(endpointType)}/${reportId}`

  yield all([
    yield put({
      type: requestActions.AUTHED_REQUEST,
      method: 'GET',
      url,
      successAction: success(actions.FETCH_REPORT),
      failureAction: failure(actions.FETCH_REPORT),
      endpointType
    }),
    yield put(fetchNextAndPreviousReportIds(reportId, endpointType))
  ])
}

function * fetchRelatedReports (action) : Generator<any, any, any> {
  const { endpointType } = action
  const {
    data: {
      reportType,
      program: { id: program }
    }
  } = yield select(state => state.report)

  const query = { filter: { program, reportType }, sort: '-generation_time' }
  const url = `${API_URL}/${getEndpointByDocType(endpointType)}?${stringify(query)}`

  yield put({
    type: requestActions.AUTHED_REQUEST,
    method: 'GET',
    url,
    successAction: success(actions.FETCH_RELATED_REPORTS),
    failureAction: failure(actions.FETCH_RELATED_REPORTS)
  })
}

function * fetchReportActivity (action) : Generator<any, any, any> {
  const { newOffset, endpointType } = action

  if (endpointType === ENDPOINT_TYPES.certificate) {
    return
  }

  const {
    data: { id },
    activityPagination: { offset = 0 }
  } = yield select(state => state.report)
  const query = { page: { offset: newOffset || offset } }

  const baseUrl = `${API_URL}/${REPORTS_ENDPOINT}/${id}/activity`
  const url = `${baseUrl}?${stringify(query)}`

  yield put({
    type: requestActions.AUTHED_REQUEST,
    method: 'GET',
    url,
    successAction: success(actions.API_REPORTS_ACTIVITY_INDEX),
    failureAction: failure(actions.API_REPORTS_ACTIVITY_INDEX)
  })
}

function * fetchReportSuccess (action) : Generator<any, any, any> {
  const {
    endpointType: docType,
    data: {
      id,
      participant: { id: participantId }
    }
  } = action

  yield all([
    yield put(updateReportUserStatus({
      reportId: id,
      read: true,
      docType
    })),
    yield put(fetchMemberships(participantId))
  ])
}

function * handleAttachmentErrors (errors) {
  if (errors.token) {
    yield put(expireSession())
  } else {
    yield put({
      type: failure(actions.API_REPORTS_ATTACHMENTS_CREATE),
      errors: camelizeKeys(errors)
    })
  }
}

function * uploadAttachmentFile (action, data) {
  const { reportId, attachment: { file } } = action
  const { id, putUrl: url } = camelizeKeys(data)
  yield uploadFile({ url, file })
  const { success, error } = yield select(state => state.uploads[url])
  if (success) {
    yield put({
      type: actions.API_REPORTS_ATTACHMENTS_UPDATE,
      reportId,
      attachment: { id, status: 'uploaded' }
    })
  } else if (error) {
    yield put({
      type: failure(actions.API_REPORTS_ATTACHMENTS_CREATE),
      reportId,
      errors: error
    })
  }
}

function * createReportAttachment (action) : Generator<any, any, any> {
  const { reportId, attachment: { file } } = action
  const url = `${API_URL}/${REPORTS_ENDPOINT}/${reportId}/attachments`
  const body = {
    data: decamelizeKeys({
      filename: file.name,
      title: file.name,
      contentType: file.type
    })
  }
  const method = 'POST'
  const token = yield select(state => state.auth.token)

  try {
    const response = yield call(fetch, url, { body, method, token })
    const { body: result } = response
    if (result.errors) {
      yield handleAttachmentErrors(result.errors)
    } else {
      yield uploadAttachmentFile(action, result.data)
    }
  } catch (e) {
    yield put({
      type: failure(actions.API_REPORTS_ATTACHMENTS_CREATE),
      errors: camelizeKeys(e)
    })
  }
}

function * updateReportAttachment (action) : Generator<any, any, any> {
  const { id, ...data } = action.attachment
  const url = `${API_URL}/${REPORTS_ENDPOINT}/${action.reportId}/attachments/${id}`

  yield put({
    type: requestActions.AUTHED_REQUEST,
    method: 'PUT',
    url,
    body: { data },
    successAction: success(actions.API_REPORTS_ATTACHMENTS_UPDATE),
    failureAction: failure(actions.API_REPORTS_ATTACHMENTS_UPDATE)
  })
}

function * deleteReportAttachment (action) : Generator<any, any, any> {
  const { id } = action.attachment
  const url = `${API_URL}/${REPORTS_ENDPOINT}/${action.reportId}/attachments/${id}`

  yield put({
    type: requestActions.AUTHED_REQUEST,
    method: 'DELETE',
    url,
    successAction: success(actions.API_REPORTS_ATTACHMENTS_DESTROY),
    failureAction: failure(actions.API_REPORTS_ATTACHMENTS_DESTROY)
  })
}

function * createReportComment (action) : Generator<any, any, any> {
  const url = `${API_URL}/${REPORTS_ENDPOINT}/${action.reportId}/comments`

  yield put({
    type: requestActions.AUTHED_REQUEST,
    method: 'POST',
    url,
    body: { data: action.comment },
    successAction: success(actions.API_REPORTS_COMMENTS_CREATE),
    failureAction: failure(actions.API_REPORTS_COMMENTS_CREATE)
  })
}

function * updateReportComment (action) : Generator<any, any, any> {
  const { id, ...data } = action.comment
  const url = `${API_URL}/${REPORTS_ENDPOINT}/${action.reportId}/comments/${id}`

  yield put({
    type: requestActions.AUTHED_REQUEST,
    method: 'PUT',
    url,
    body: { data },
    successAction: success(actions.API_REPORTS_COMMENTS_UPDATE),
    failureAction: failure(actions.API_REPORTS_COMMENTS_UPDATE)
  })
}

function * deleteReportComment (action) : Generator<any, any, any> {
  const { id } = action.comment
  const url = `${API_URL}/${REPORTS_ENDPOINT}/${action.reportId}/comments/${id}`

  yield put({
    type: requestActions.AUTHED_REQUEST,
    method: 'DELETE',
    url,
    successAction: success(actions.API_REPORTS_COMMENTS_DESTROY),
    failureAction: failure(actions.API_REPORTS_COMMENTS_DESTROY)
  })
}

function * createReportReviewerRequest (action) : Generator<any, any, any> {
  const { reportId, userIds } = action
  const url = `${API_URL}/${REPORTS_ENDPOINT}/${reportId}/reviewer_request`

  yield put({
    type: requestActions.AUTHED_REQUEST,
    method: 'POST',
    url,
    body: { data: decamelizeKeys({ userIds }) },
    successAction: success(actions.API_REPORTS_REVIEWER_REQUESTS_CREATE),
    failureAction: failure(actions.API_REPORTS_REVIEWER_REQUESTS_CREATE)
  })
}

function * createReportReviewerRemoval (action) : Generator<any, any, any> {
  const { reportId, userId } = action
  const url = `${API_URL}/${REPORTS_ENDPOINT}/${reportId}/reviewer_removal`

  yield put({
    type: requestActions.AUTHED_REQUEST,
    method: 'POST',
    url,
    body: { data: decamelizeKeys({ userId }) },
    successAction: success(actions.API_REPORTS_REVIEWER_REMOVALS_CREATE),
    failureAction: failure(actions.API_REPORTS_REVIEWER_REMOVALS_CREATE)
  })
}

function * updateReportReviewStatus (action) : Generator<any, any, any> {
  const { reportId, reviewStatus } = action
  const url = `${API_URL}/${REPORTS_ENDPOINT}/${reportId}/review_status`

  yield put(setReportInProgress(reportId))

  yield put({
    type: requestActions.AUTHED_REQUEST,
    method: 'PUT',
    url,
    body: { data: decamelizeKeys({ reviewStatus }) },
    successAction: success(actions.API_REPORTS_REVIEW_STATUSES_UPDATE),
    failureAction: failure(actions.API_REPORTS_REVIEW_STATUSES_UPDATE)
  })
}

function * updateReportReviewStatusInList () : Generator<any, any, any> {
  const { id, reviewStatus } = yield select((state) => state.report.data)

  yield put(setSingleReportReviewStatus(id, reviewStatus))
  yield put(updateReportUserStatus({
    reportId: id,
    read: true,
    docType: DOC_TYPES.report,
  }))
}

function * failToUpdateReportReviewStatusInList () : Generator<any, any, any> {
  yield put(setReportInProgress(null))
}

function * updateReportFollowUpLabel (action) : Generator<any, any, any> {
  const { reportId, followUpLabel, customFollowUpLabelId, labelNote } = action
  const url = `${API_URL}/${REPORTS_ENDPOINT}/${reportId}/follow_up_label`

  const data = decamelizeKeys({
    followUpLabel,
    customFollowUpLabelId,
    labelNote
  })

  yield put({
    type: requestActions.AUTHED_REQUEST,
    method: 'PUT',
    url,
    body: { data },
    successAction: success(actions.API_REPORTS_FOLLOW_UP_LABELS_UPDATE),
    failureAction: failure(actions.API_REPORTS_FOLLOW_UP_LABELS_UPDATE)
  })
}

function * fetchReportCustomFollowUpLabels (action) : Generator<any, any, any> {
  const url = `${API_URL}/${REPORTS_ENDPOINT}/${action.reportId}/follow_up_labels`

  yield put({
    type: requestActions.AUTHED_REQUEST,
    hideLoading: true,
    method: 'GET',
    url,
    successAction: success(actions.API_REPORTS_FOLLOW_UP_LABELS_INDEX),
    failureAction: failure(actions.API_REPORTS_FOLLOW_UP_LABELS_INDEX)
  })
}

function * reloadReports (action) : Generator<any, any, any> {
  yield put(fetchReportFacets())
  yield put(fetchReports())
}

export default function * reportSagas () : Generator<any, any, any> {
  yield all([
    yield takeLatest(actions.FETCH_REPORT, fetchReport),
    yield takeEvery(success(actions.FETCH_REPORT), fetchReportSuccess),
    yield takeEvery(success(actions.FETCH_REPORT), fetchRelatedReports),
    yield takeEvery(success(actions.FETCH_REPORT), fetchReportActivity),
    yield takeEvery(actions.API_REPORTS_ATTACHMENTS_CREATE, createReportAttachment),
    yield takeEvery(actions.API_REPORTS_ATTACHMENTS_UPDATE, updateReportAttachment),
    yield takeEvery(actions.API_REPORTS_ATTACHMENTS_DESTROY, deleteReportAttachment),
    yield takeEvery(actions.API_REPORTS_COMMENTS_CREATE, createReportComment),
    yield takeEvery(actions.API_REPORTS_COMMENTS_UPDATE, updateReportComment),
    yield takeEvery(actions.API_REPORTS_COMMENTS_DESTROY, deleteReportComment),
    yield takeEvery(actions.API_REPORTS_REVIEWER_REQUESTS_CREATE, createReportReviewerRequest),
    yield takeEvery(actions.API_REPORTS_REVIEWER_REMOVALS_CREATE, createReportReviewerRemoval),
    yield takeEvery(actions.API_REPORTS_REVIEW_STATUSES_UPDATE, updateReportReviewStatus),
    yield takeEvery(actions.API_REPORTS_FOLLOW_UP_LABELS_UPDATE, updateReportFollowUpLabel),
    yield takeEvery(success(actions.API_REPORTS_FOLLOW_UP_LABELS_UPDATE), reloadReports),
    yield takeEvery(success(actions.API_REPORTS_REVIEW_STATUSES_UPDATE), updateReportReviewStatusInList),
    yield takeEvery(failure(actions.API_REPORTS_REVIEW_STATUSES_UPDATE), failToUpdateReportReviewStatusInList),
    yield takeLatest(actions.API_REPORTS_FOLLOW_UP_LABELS_INDEX, fetchReportCustomFollowUpLabels),
    yield takeLatest(actions.API_REPORTS_ACTIVITY_INDEX, fetchReportActivity)
  ])
}
